import { createContext, useContext } from "react";

import { ConnectorOption } from "@/features/connectors";

export const RecommendedConnectionsContext = createContext<{
  options: ConnectorOption[];
  onSelectIntegration: (option: ConnectorOption) => void;
  onRemoveRecommendation: (option: ConnectorOption) => Promise<void>;
} | null>(null);

export function useRecommendedConnectionsContext() {
  const context = useContext(RecommendedConnectionsContext);
  if (!context) {
    throw new Error(
      "useRecommendedConnectionsContext must be used within a RecommendedConnectionsProvider",
    );
  }
  return context;
}
