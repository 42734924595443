import React from "react";

type Props = {
  className?: string;
};

const EcommerceWeldIcon = (props: Props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <mask
        id="mask0_2554_15611"
        style={{ maskType: "alpha" }}
        width="100"
        height="100"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="100" height="100" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_2554_15611)">
        <path fill="#6D717A" d="M-66 -34H166V134H-66z"></path>
        <path
          stroke="#515862"
          strokeWidth="9"
          d="M6 27h7.632a6 6 0 015.893 4.87l.283 1.475m5.128 26.741h30.368a6 6 0 005.311-3.209l7.745-14.74c2.1-3.996-.798-8.792-5.311-8.792H19.808m5.128 26.741l-5.128-26.74m5.128 26.74l-2.403 3.186a6 6 0 000 7.226l2.403 3.185m0 0h33.121a6 6 0 016 6V84a6 6 0 01-6 6h-27.12a6 6 0 01-6-6V73.683z"
        ></path>
        <path
          fill="#fff"
          d="M94 24.5a17.5 17.5 0 11-35 0 17.5 17.5 0 0135 0z"
        ></path>
        <circle
          r="8.065"
          fill="#515862"
          transform="rotate(-179.603 38.257 12.198) skewX(.058)"
        ></circle>
      </g>
    </svg>
  );
};

export default EcommerceWeldIcon;
