import React from "react";

type Props = {
  className?: string;
};

const CustomersWeldIcon = (props: Props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <mask
        id="mask0_2554_15601"
        style={{ maskType: "alpha" }}
        width="100"
        height="100"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="100" height="100" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_2554_15601)">
        <ellipse
          cx="55.173"
          cy="55.171"
          fill="#000"
          rx="103.448"
          ry="82.759"
        ></ellipse>
        <ellipse
          cx="46.471"
          cy="53.529"
          fill="#6D717A"
          rx="76.471"
          ry="73.529"
          transform="rotate(-180 46.47 53.53)"
        ></ellipse>
        <path
          fill="#fff"
          d="M67.5 31.904a17.375 17.375 0 11-34.75 0 17.375 17.375 0 0134.75 0zm34.75 17.375a11.582 11.582 0 01-19.774 8.191 11.583 11.583 0 1119.774-8.19zm-81.083 0A11.583 11.583 0 11-2 49.28a11.583 11.583 0 0123.167 0z"
        ></path>
        <path
          fill="#515862"
          d="M107.833 107.196H-8V95.613a17.375 17.375 0 0131.02-10.755 28.97 28.97 0 0153.793 0 17.374 17.374 0 0131.02 10.755v11.583z"
        ></path>
        <circle
          r="8.007"
          fill="#000"
          transform="rotate(-179.603 25.082 15.945) skewX(.058)"
        ></circle>
        <circle
          r="4.503"
          fill="#000"
          transform="rotate(-179.603 4.84 24.747) skewX(.058)"
        ></circle>
        <circle
          r="4.503"
          fill="#000"
          transform="rotate(-179.603 45.35 24.608) skewX(.058)"
        ></circle>
      </g>
    </svg>
  );
};

export default CustomersWeldIcon;
