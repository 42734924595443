import { ComponentProps, ReactNode } from "react";

import { ConnectionBaseFragment } from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { ConnectorOption } from "@/features/connectors";
import { IntegrationLogoBox } from "@/integrations";
import { IntegrationLogo } from "@/integrations";
import HRWithText from "@/pages/Setup/components/HRWithText";
import { Dialog } from "@headlessui/react";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";

import { ExistingConnectionSelect } from "../ExistingConnectionSelect";

const dialogContentRegistry: {
  [integrationId: string]: {
    title: string;
    body: ReactNode;
  };
} = {
  "google-drive__excel": {
    title: "Sync Excel files with Google Drive",
    body: (
      <>
        <p>
          The Google Drive connector can handle your Excel files (.xlsx, .xls).
        </p>
        <p>
          Connect your Google Drive account to WELD and start syncing your Excel
          files.
        </p>
      </>
    ),
  },
  "google-drive__csv": {
    title: "Sync CSV files with Google Drive",
    body: (
      <>
        <p>The Google Drive connector can handle your CSV files.</p>
        <p>
          Connect your Google Drive account to WELD and start syncing your CSV
          files.
        </p>
      </>
    ),
  },
  onedrive__excel: {
    title: "Sync Excel files with OneDrive",
    body: (
      <>
        <p>The OneDrive connector can handle your Excel files (.xlsx, .xls).</p>
        <p>
          Connect your OneDrive account to WELD and start syncing your Excel
          files.
        </p>
      </>
    ),
  },
  onedrive__csv: {
    title: "Sync CSV files with OneDrive",
    body: (
      <>
        <p>The OneDrive connector can handle your CSV files.</p>
        <p>
          Connect your OneDrive account to WELD and start syncing your CSV
          files.
        </p>
      </>
    ),
  },
  sharepoint: {
    title: "Sync Sharepoint files with OneDrive connector",
    body: (
      <>
        <p>The OneDrive connector can handle your files from Sharepoint.</p>
        <p>
          Connect your organization account to WELD and start syncing your files
          from Sharepoint.
        </p>
      </>
    ),
  },
  sharepoint__csv: {
    title: "Sync CSV files with OneDrive connector",
    body: (
      <>
        <p>The OneDrive connector can handle your CSV files from Sharepoint.</p>
        <p>
          Connect your organization account to WELD and start syncing your Excel
          files.
        </p>
      </>
    ),
  },
  sharepoint__excel: {
    title: "Sync Excel files with OneDrive connector",
    body: (
      <>
        <p>
          The OneDrive connector can handle your Excel files (.xlsx, .xls) from
          Sharepoint.
        </p>
        <p>
          Connect your organization account to WELD and start syncing your Excel
          files.
        </p>
      </>
    ),
  },
  ftp__csv: {
    title: "Sync CSV files from FTP",
    body: (
      <>
        <p>The FTP connector can handle your CSV files.</p>
        <p>Connect your FTP server to WELD and start syncing your CSV files.</p>
      </>
    ),
  },
  sftp__csv: {
    title: "Sync CSV files from SFTP",
    body: (
      <>
        <p>The SFTP connector can handle your CSV files.</p>
        <p>
          Connect your SFTP server to WELD and start syncing your CSV files.
        </p>
      </>
    ),
  },
  "aws-s3__csv": {
    title: "Sync CSV files from S3",
    body: (
      <>
        <p>The AWS S3 connector can handle your CSV files.</p>
        <p>Connect your S3 bucket to WELD and start syncing your CSV files.</p>
      </>
    ),
  },
};

export function AliasConnectorDialog({
  option,
  existingConnections = [],
  onSelectIntegration,
  onSelectConnection,
  ...dialogProps
}: {
  option: ConnectorOption;
  existingConnections?: ConnectionBaseFragment[] | undefined;
  onSelectIntegration: (option: ConnectorOption) => void;
  onSelectConnection: (option: ConnectionBaseFragment) => void;
} & ComponentProps<typeof Modal>) {
  const { title, body } = dialogContentRegistry[option.value];
  if (!title || !body) {
    return null;
  }
  return (
    <Modal {...dialogProps}>
      <ModalHeader className="flex items-center justify-start gap-4">
        <IntegrationLogoBox id={option.integration.id} />
        <Dialog.Title className="text-lg">{title}</Dialog.Title>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody className="flex flex-col gap-4 pb-6">
        {body}
        <div className="flex flex-col items-center gap-2">
          <div>
            {existingConnections.length > 0 && (
              <>
                <ExistingConnectionSelect
                  options={existingConnections}
                  onChange={(connection) => {
                    onSelectConnection?.(connection);
                  }}
                >
                  <SecondaryButton
                    size="sm"
                    icon={<IntegrationLogo id={option.integration.id} />}
                    iconRight={
                      existingConnections.length > 1 ? (
                        <ChevronDownIcon />
                      ) : undefined
                    }
                  >
                    Use existing connection
                  </SecondaryButton>
                </ExistingConnectionSelect>
                <HRWithText className="mt-2">or</HRWithText>
              </>
            )}
          </div>
          <PrimaryButton
            size="sm"
            onClick={() => onSelectIntegration(option)}
            icon={<PlusIcon className="h-5 w-5" />}
            variant={existingConnections.length > 0 ? "outline" : "solid"}
          >
            Create {option.integration.name} connection
          </PrimaryButton>
        </div>
      </ModalBody>
    </Modal>
  );
}
