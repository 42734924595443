import React, { createContext } from "react";

import { ConnectionBaseFragment } from "@/apollo/types";
import { ConnectorOption } from "@/features/connectors";

import { useConnectors } from "./useConnectors";

export const ConnectorsContext = createContext<
  | (ReturnType<typeof useConnectors> & {
      onSelectIntegration: (option: ConnectorOption) => void;
      onSelectConnection?: (connection: ConnectionBaseFragment) => void;
      getExistingConnections: (
        integrationId: string,
      ) => ConnectionBaseFragment[];
      enableSelectExistingConnection: boolean;
      filters: {
        searchText: boolean;
      };
    })
  | null
>(null);

export function useConnectorsContext() {
  const ctx = React.useContext(ConnectorsContext);
  if (!ctx) {
    throw new Error("ConnectorsContext is not set");
  }
  return ctx;
}
