// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
/* eslint-disable no-console */

import { Workbox } from "workbox-window";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

type Config = {
  checkForUpdatesInterval: number;
  onRegistered?: () => void;
  onUpdateAvailable?: (onAccept: CallableFunction) => void;
};

export function register(config: Config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      // Do not change the name of the generated service worker file!
      // This can cause issues with service worker caching.
      // https://web.dev/service-worker-lifecycle/#avoid-url-change
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "This web app is being served cache-first by a service worker",
          );
          console.warn(
            "Service worker running on localhost. This should be user for testing only. It can otherwise cause unexpected behaviour due to caching of resources.",
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function runPeriodicUpdates(workbox: Workbox, delay: number) {
  setInterval(async () => {
    try {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      if (!navigator.onLine) {
        return;
      }
      const resp = await fetch(swUrl, {
        method: "HEAD",
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      if (resp?.status === 200) {
        await workbox.update();
      }
    } catch (e) {
      // just ignore
    }
  }, delay);
}

async function registerValidSW(swUrl: string, config: Config) {
  try {
    // Check to see if the page is currently controlled by a service worker.
    let isControlled = Boolean(navigator.serviceWorker.controller);

    const wb = new Workbox(swUrl);

    // When the user asks to refresh the UI, we'll need to reload the window.
    // Using a variable to make sure we refresh the page only once, or it could
    // cause an infinite refresh loop when using the Chrome Dev Tools "Update on Reload" feature
    let preventDevToolsReloadLoop = false;
    wb.addEventListener("controlling", () => {
      // This fires when the service worker controlling this page
      // changes, eg a new worker has skipped waiting and become
      // the new active worker.
      if (isControlled) {
        // If the page was previosly controlled, reload the page.
        // Ensure refresh is only called once.
        if (preventDevToolsReloadLoop) return;
        preventDevToolsReloadLoop = true;
        window.location.reload();
      } else {
        // Otherwise, set the flag for future updates, but don't reload.
        isControlled = true;
      }
    });

    wb.addEventListener("waiting", () => {
      // We have an updated service worker, but it can't activate until all tabs running the current version have fully unloaded.
      // We are going to give control back to the user, and let the user decide whether to update the app or not for a better UX.
      config.onUpdateAvailable?.(() => {
        // At this point the user accepted the update, so we can skip waiting and reload the page.
        // This will trigger a `controllerchange` event on all (due to `clientsClaim`) active service workers.
        wb.messageSkipWaiting();
      });
    });

    const registration = await wb.register();

    // Immediately check if there is an update available, i.e. we have a new service worker in its waiting state.
    if (registration?.waiting?.state === "installed") {
      const searchParams = new URLSearchParams(window.location.search);
      const isCheckoutRoute = searchParams.get("checkout") !== null;
      // Avoid auto-refreshing the page if we are on the checkout route since this can disrupt the checkout flow.
      if (!isCheckoutRoute) {
        // Send a message to the waiting service worker, instructing it to activate.
        // This will trigger a `controllerchange` event on all (due to `clientsClaim`) active service workers.
        wb.messageSkipWaiting();
      }
    }

    if (typeof config.onRegistered === "function") {
      config.onRegistered();
    }

    // Check for updates periodically
    runPeriodicUpdates(wb, config.checkForUpdatesInterval);
  } catch (error) {
    console.error("Error during service worker registration:", error);
  }
}

function checkValidServiceWorker(swUrl: string, config: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode.",
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
