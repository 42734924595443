import { ComponentProps } from "react";

import { ReactComponent as PlugIcon } from "@/assets/icons/plug.svg";
import IconWithBG from "@/components/elements/IconWithBG";
import { RequestConnectorForm } from "@/features/connectors";

export function RequestConnector(
  props: ComponentProps<typeof RequestConnectorForm>,
) {
  return (
    <div className="w-full max-w-sm rounded border bg-white p-4 dark:bg-gray-800">
      <div className="mb-2 flex items-center gap-3">
        <IconWithBG
          size="xs"
          icon={<PlugIcon className="text-indigo-600" />}
          className="bg-indigo-600/20"
        />
        <div className="font-medium leading-none">Request a connector</div>
      </div>
      <RequestConnectorForm {...props} />
    </div>
  );
}
