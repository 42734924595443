import React, { forwardRef } from "react";

import { classed } from "@/components/classed.config";
import { ButtonCard } from "@/components/elements/ButtonCard";
import classNames from "@/helpers/classNames";
import { CheckIcon } from "@heroicons/react/24/solid";

export const ConnectorCard = forwardRef<
  HTMLButtonElement,
  {
    isChecked?: boolean;
    icon: React.ReactNode;
  } & React.ComponentProps<typeof ButtonCard>
>((props, ref) => {
  const { className, children, isChecked, ...buttonProps } = props;
  return (
    <ButtonCard
      ref={ref}
      {...buttonProps}
      className={classNames(
        "group relative w-full items-center justify-start gap-4 rounded-sm p-4 text-left",
        className,
      )}
    >
      {props.icon}
      {isChecked && (
        <div className="absolute bottom-0 right-0 m-2">
          <div className="flex h-5 w-5 items-center justify-center rounded-full bg-green-600/80 text-white">
            <CheckIcon className="h-3" />
          </div>
        </div>
      )}
      {children}
    </ButtonCard>
  );
});

export const ConnectorCardContent = classed.div("flex min-w-0 flex-col gap-1");
export const ConnectorCardTitle = classed.div(
  "w-full truncate text-sm font-medium leading-4 dark:text-white",
);
export const ConnectorCardLabels = classed.div(
  "flex flex-wrap items-center gap-1",
);
