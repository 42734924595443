import React from "react";

import {
  ConnectionBaseFragment,
  ConnectionsDocument,
  ExampleDatabaseTypes,
  useCreateExampleConnectionMutation,
} from "@/apollo/types";
import AppearTransition from "@/components/elements/AppearTransition";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { Modal, ModalBody, ModalHeader } from "@/components/elements/Modal";
import { useConnections } from "@/features/connectors";
import classNames from "@/helpers/classNames";
import { IntegrationLogo } from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import CustomersWeldIcon from "./icons/CustomersWeldIcon";
import EcommerceWeldIcon from "./icons/EcommerceWeldIcon";

const SampleDatasets = Object.values(ExampleDatabaseTypes);

export const SetupSampleDataDialog = (props: {
  show: boolean;
  onClose: () => void;
  onSelectConnection?: (connection: ConnectionBaseFragment) => void;
}) => {
  const toast = useToast();

  const sampleConnections = useSampleDataConnection();

  const [createExampleConnection, createExampleConnectionMutation] =
    useCreateExampleConnectionMutation({
      refetchQueries: [ConnectionsDocument],
    });

  const handleCreateExampleConnection = async (
    SampleDataName: ExampleDatabaseTypes,
  ) => {
    if (createExampleConnectionMutation.loading) return;
    createExampleConnection({
      variables: { input: { SampleDataName } },
    })
      .then(({ data }) => {
        props.onClose();
        if (data?.createExampleConnection) {
          props.onSelectConnection?.(
            data?.createExampleConnection as ConnectionBaseFragment,
          );
        }
        toast("Success", "You are now connected to our sample data", "success");
      })
      .catch((e) => {
        props.onClose();
        toast("Error creating example connection", e.message, "error");
      });
  };

  return (
    <Modal onClose={props.onClose} isOpen={props.show}>
      <ModalHeader>Get started with our test datasets</ModalHeader>
      <ModalBody className="pb-5">
        <div className="mb-4 text-sm dark:text-gray-200">
          Try our test datasets as a starting point, to get started trying out
          weld.
        </div>
        <div className="space-y-4">
          {SampleDatasets.map((datasetType) => {
            const connection = sampleConnections.find(
              (c) => c.label === datasetType,
            );
            return (
              <SampleDataButton
                key={datasetType}
                added={!!connection}
                datasetType={datasetType}
                onClick={() => {
                  if (connection) {
                    props.onClose();
                    props.onSelectConnection?.(connection);
                  } else handleCreateExampleConnection(datasetType);
                }}
              />
            );
          })}
        </div>
        {createExampleConnectionMutation.loading && (
          <AppearTransition>
            <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-gray-200 opacity-25">
              <LoadingSpinner className="w-6" />
            </div>
          </AppearTransition>
        )}
      </ModalBody>
    </Modal>
  );
};

const SampleDataButton = (props: {
  datasetType: ExampleDatabaseTypes;
  onClick: () => void;
  added: boolean;
}) => {
  return (
    <div
      onClick={() => props.onClick()}
      className={classNames(
        props.added ? "bg-gray-100 dark:bg-gray-600" : "",
        "relative flex cursor-pointer items-center rounded border hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-600",
      )}
    >
      <div className="flex items-center justify-center p-2">
        <div className="relative h-20 w-20 rounded">
          <SampleIcon datasetType={props.datasetType} />
          <IntegrationLogo
            className="absolute bottom-0 right-0 -mb-1 -mr-1 h-6 w-6"
            id={"postgresql"}
          />
        </div>
      </div>
      <div className="px-4">
        <div className="font-md">{getHeadling(props.datasetType)}</div>
        <div className="mb-2 text-xs">{getDescription(props.datasetType)}</div>
      </div>
      {props.added && (
        <div className="absolute left-0 top-0 -ml-4 -mt-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-green-500">
          <CheckCircleIcon style={{}} className="h-10 w-10 flex-none" />
        </div>
      )}
    </div>
  );
};

const useSampleDataConnection = () => {
  const { connections } = useConnections("EltSourceConnection");
  return connections?.filter((c) =>
    Object.values(ExampleDatabaseTypes).includes(
      c.label as ExampleDatabaseTypes,
    ),
  );
};

const getHeadling = (datasetType: ExampleDatabaseTypes) => {
  switch (datasetType) {
    case ExampleDatabaseTypes.ECommerceSample:
      return "E-commerce sample data";
    default:
      return "Sample data";
  }
};

const getDescription = (datasetType: ExampleDatabaseTypes) => {
  switch (datasetType) {
    case ExampleDatabaseTypes.ECommerceSample:
      return "An example database containing randomly generated customers, orders, and order_lines.";
    default:
      return "A set of sample data";
  }
};

const SampleIcon = (props: { datasetType: ExampleDatabaseTypes }) => {
  switch (props.datasetType) {
    case ExampleDatabaseTypes.ECommerceSample:
      return <EcommerceWeldIcon className="h-full w-full" />;
    default:
      return <CustomersWeldIcon className="h-full w-full" />;
  }
};
