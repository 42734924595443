import classNames from "@/helpers/classNames";
import { Ability, hasAbility } from "@/integrations";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { RadioGroup } from "@headlessui/react";

import { useConnectorsContext } from "../ConnectorsProvider";
import { abilityFilters } from "./constants";

export function AbilityFilter() {
  const { abilityFilter, setAbilityFilter } = useConnectorsContext();

  const dwh = useDataWarehouseContext();
  const dataWarehouseHasBothAbilities = hasAbility(
    dwh.integration,
    "EltDestinationConnection",
    "ReverseEltSourceConnection",
  );

  if (!dataWarehouseHasBothAbilities) {
    return null;
  }

  return (
    <RadioGroup
      value={abilityFilter}
      onChange={(value: Ability) => {
        setAbilityFilter(value);
      }}
    >
      <RadioGroup.Label className="sr-only">Choose a filter</RadioGroup.Label>
      <div className="flex items-center space-x-3">
        {[{ label: "All", value: null }, ...abilityFilters].map((filter) => (
          <RadioGroup.Option
            key={filter.value}
            value={filter.value}
            className={({ active, checked }) =>
              classNames(
                "h-10 cursor-pointer focus:outline-none",
                active ? "ring-2 ring-blue-500 ring-offset-2" : "",
                checked
                  ? "border-transparent bg-blue-600 text-white hover:bg-blue-700"
                  : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:text-white",
                "rounded-xs flex items-center justify-center border px-3 py-3 text-sm font-medium uppercase",
              )
            }
          >
            <RadioGroup.Label as="p">{filter.label}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
