import React from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

import { ConnectionBaseFragment } from "@/apollo/types";
import { IntegrationLogo, isCustomConnector } from "@/integrations";
import { Listbox, Transition } from "@headlessui/react";

export function ExistingConnectionSelect({
  children,
  options,
  onChange,
}: {
  children: React.ReactElement;
  options: ConnectionBaseFragment[];
  onChange: (connection: ConnectionBaseFragment) => void;
}) {
  let [referenceElement, setReferenceElement] =
    React.useState<HTMLDivElement | null>();
  let [popperElement, setPopperElement] =
    React.useState<HTMLUListElement | null>();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
    strategy: "absolute",
  });

  if (options.length === 1 && !isCustomConnector(options[0].integrationId)) {
    return React.cloneElement(React.Children.only(children), {
      onClick: () => {
        onChange(options[0]);
      },
    });
  }
  const integrationId = options[0].integrationId;
  return (
    <Listbox onChange={onChange}>
      <Listbox.Button as="div">
        {React.cloneElement(React.Children.only(children), {
          ref: setReferenceElement,
          role: "button",
        })}
      </Listbox.Button>
      {createPortal(
        <Transition
          as={React.Fragment}
          leave="transition ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            as={"ul"}
            ref={(elm) => setPopperElement(elm as HTMLUListElement | null)}
            style={styles.popper}
            {...attributes.popper}
            className="absolute z-50 rounded-md bg-white text-base shadow-xl outline-2 outline-blue-500 ring-1 ring-black ring-opacity-5 focus-within:outline focus:outline dark:bg-gray-800 dark:ring-gray-600"
          >
            <div className="flex items-center border-b px-4 py-2 font-medium">
              <IntegrationLogo id={integrationId} className="mr-3 h-5 w-5" />{" "}
              Select an existing connection
            </div>
            <div className="max-h-60 divide-y overflow-auto py-1">
              {options.map((connection, i) => (
                <Listbox.Option
                  key={connection.id}
                  as="li"
                  value={connection}
                  className={({ active }) =>
                    `cursor-default select-none px-4 py-2 ${
                      active
                        ? "bg-blue-500 text-white"
                        : "bg-white dark:bg-gray-800 dark:text-white"
                    }`
                  }
                >
                  {connection.label}
                </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </Transition>,
        document.body,
      )}
    </Listbox>
  );
}
