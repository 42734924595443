import { ComponentProps } from "react";

import { PrimaryButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import Github from "@/components/icons/logos/Github";
import { ConnectorOption } from "@/features/connectors";
import { useMountEffect } from "@/hooks/useMountEffect";
import { IntegrationLogoBox } from "@/integrations";
import { useMixpanel } from "@/monitoring/mixpanel";
import { Dialog } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export function CommunityCustomConnectorDialog({
  option,
  onConnect,
  ...dialogProps
}: {
  option: ConnectorOption;
  onConnect: (option: ConnectorOption) => void;
} & ComponentProps<typeof Modal>) {
  const name = option.label;
  const mp = useMixpanel();

  useMountEffect(() => {
    mp.track("Community Custom Connector Dialog Opened", {
      connector: name,
    });
  });

  return (
    <Modal {...dialogProps}>
      <ModalHeader className="flex items-center justify-start gap-4">
        <IntegrationLogoBox id={option.value} />
        <Dialog.Title className="text-lg">
          {name} is a Custom Connector
        </Dialog.Title>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody className="flex flex-col gap-4">
        <span>
          The {name} integration is a Custom Connector built by the{" "}
          <strong>WELD Community</strong>.
        </span>
        <div className="flex flex-col gap-4">
          <span>
            Community-built Custom Connectors are provided to you as source
            code, which you can configure to meet your unique requirements and
            host on your own infrastructure.
          </span>
          <div className="flex gap-4">
            <a
              href="https://github.com/WeldHQ/community-connectors"
              target="_blank"
              rel="noreferrer"
              className="relative inline-flex h-8 min-w-[32px] shrink-0 items-center justify-center self-center rounded-sm border border-gray-200 bg-gray-100/0 px-3 text-center text-xs font-medium text-gray-700 shadow-sm outline-none ring-offset-white transition-colors focus-within:ring-2 hover:bg-gray-100/100 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:border-gray-700 dark:bg-gray-700/0 dark:text-gray-100 dark:ring-offset-gray-900 dark:hover:bg-gray-700/75"
              onClick={() => {
                mp.track("Community Custom Connector Source Code Clicked", {
                  connector: name,
                });
              }}
            >
              View source code <Github className="ml-1.5 h-3 w-3" />
            </a>
            <a
              href="https://weld.app/docs/custom-connector"
              target="_blank"
              rel="noreferrer"
              className="relative inline-flex h-8 min-w-[32px] shrink-0 items-center justify-center self-center rounded-sm border border-gray-200 bg-gray-100/0 px-3 text-center text-xs font-medium text-gray-700 shadow-sm outline-none ring-offset-white transition-colors focus-within:ring-2 hover:bg-gray-100/100 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:border-gray-700 dark:bg-gray-700/0 dark:text-gray-100 dark:ring-offset-gray-900 dark:hover:bg-gray-700/75"
              onClick={() => {
                mp.track("Community Custom Connector Setup Guide Clicked", {
                  connector: name,
                });
              }}
            >
              View setup guide{" "}
              <ArrowTopRightOnSquareIcon className="ml-1.5 h-3 w-3" />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <span>
            If your {name} connector is already set up and running, you are
            ready to connect to it through WELD:
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        <PrimaryButton
          size="sm"
          onClick={() => {
            onConnect(option);
            mp.track("Community Custom Connector Connect Clicked", {
              connector: name,
            });
          }}
          className="self-start"
        >
          Connect my {name} connector
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
}
