import { useFlags } from "launchdarkly-react-client-sdk";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";

import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useCurrentAccountIdValue } from "@/providers/account";
import { OAuthError, useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

import { ClientToServerEvents } from "./ClientToServerEvents";
import { ServerToClientEvents } from "./ServerToClientEvents";

if (!process.env.REACT_APP_WEBSOCKET_API) {
  throw new Error("REACT_APP_WEBSOCKET_API is not defined");
}

type SocketInstance = Socket<ServerToClientEvents, ClientToServerEvents>;

const SocketContext = createContext<SocketInstance | null>(null);

export function SocketProvider(props: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();
  const currentAccountId = useCurrentAccountIdValue();
  const [, setIsConnected] = useState(false);

  const { testWebsocketsLongPolling } = useFlags();

  const [socket] = useState<SocketInstance>(() => {
    return io(process.env.REACT_APP_WEBSOCKET_API!, {
      autoConnect: true,
      transports: testWebsocketsLongPolling ? ["polling"] : undefined,
      auth: async (cb) => {
        try {
          const token = await getAccessTokenSilently();
          cb({
            jwt: `Bearer ${token}`,
            "x-account-id": currentAccountId,
          });
        } catch (e) {
          Sentry.captureException(
            e,
            e instanceof OAuthError
              ? {
                  extra: {
                    cause: e.cause,
                    message: e.message,
                    description: e.error_description,
                  },
                }
              : undefined,
          );
        }
      },
    });
  });

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit("join:workspace");
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onAllEvents(event: string, payload: any) {
      /* eslint-disable no-console */
      console.groupCollapsed(`%c[socket event]: ${event}`, "color: blue;");
      console.log(payload);
      console.groupEnd();
      /* eslint-enable no-console */
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    if (process.env.NODE_ENV === "development") {
      socket.onAny(onAllEvents);
    }

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.offAny(onAllEvents);
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  );
}

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocketContext must be used within SocketProvider");
  }
  return context;
};

export function useSocketEvent<TName extends keyof ServerToClientEvents>(
  eventName: TName,
  options: {
    onMessage?: ServerToClientEvents[TName];
  } = {},
) {
  const socket = useSocket();
  const onMessageRef = useLatestValueRef(options.onMessage);
  const [lastMessage, setLastMessage] =
    useState<Parameters<ServerToClientEvents[TName]>[0]>();

  useEffect(() => {
    function onEvent(resp: any) {
      onMessageRef.current?.(resp);
      setLastMessage(resp);
    }

    socket.on(eventName, onEvent as any);
    return () => {
      socket.off(eventName, onEvent as any);
    };
  }, [eventName, socket, onMessageRef]);

  return {
    lastMessage,
  };
}
