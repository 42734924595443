import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

function getLocale() {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  }
  return navigator.language;
}

export const formatDate = (
  input: dayjs.ConfigType,
  options: Intl.DateTimeFormatOptions = {},
) => {
  const date = dayjs(input).toDate();
  const locale = getLocale();
  return new Intl.DateTimeFormat(locale, options).format(date);
};

export const formatDuration = (milliseconds: number) => {
  const d = dayjs.duration(milliseconds, "milliseconds");

  const numYears = d.get("years");
  const numMonths = d.get("months");
  const numDays = d.get("days");
  const numHours = d.get("hours");
  const numMinutes = d.get("minutes");
  const numSeconds = d.get("seconds");
  const numMillis = d.get("milliseconds");

  const dynamicFormats = [
    !!numYears && "Y[y]",
    !!numMonths && "M[m]",
    !!numDays && "D[d]",
    !!numHours && "H[h]",
    !!numMinutes && "m[m]",
    !!numSeconds && "s[s]",
    !!numMillis && "SSS[ms]",
  ]
    .filter(Boolean)
    .join(" ");

  return d.format(dynamicFormats);
};

export const formatRowCount = (
  rows: number,
  options: Intl.NumberFormatOptions = {},
) => {
  const locale = getLocale();
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  }).format(rows);
};

export const formatRowCountCompact = (
  rows: number,
  options: Intl.NumberFormatOptions = {},
) => {
  const locale = getLocale();
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
    ...options,
  }).format(rows);
};

export function formatCurrency(
  valueInCents: number,
  options: Intl.NumberFormatOptions = {},
) {
  const locale = getLocale();
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
    style: "currency",
    currency: options.currency ?? "eur",
  }).format(valueInCents / 100);
}

export function formatPercent(
  value: number,
  options: Intl.NumberFormatOptions = {},
) {
  const locale = getLocale();
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
    style: "percent",
  }).format(value);
}

export function formatBytes(bytes: number) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = bytes / Math.pow(k, i);
  const unit = sizes[i];
  return `${new Intl.NumberFormat(getLocale(), {
    maximumFractionDigits: 2,
  }).format(value)} ${unit}`;
}
