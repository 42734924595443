import React, { useCallback, useState } from "react";
import { usePopper } from "react-popper";

import {
  PendingOnboardingConnectionsDocument,
  useDeleteOnboardingConnectionPreferenceMutation,
  usePendingOnboardingConnectionsQuery,
} from "@/apollo/types";
import { Badge } from "@/components/elements/Badge";
import { Button } from "@/components/elements/Button";
import { CloseButtonIcon } from "@/components/elements/CloseButton";
import { Popover } from "@/components/elements/Popover";
import { formatPlanName } from "@/features/billing";
import {
  ConnectorOption,
  useRecommendedConnectorOptions,
} from "@/features/connectors";
import { Ability, IntegrationLogoBox } from "@/integrations";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import {
  ConnectorCard,
  ConnectorCardContent,
  ConnectorCardLabels,
  ConnectorCardTitle,
  Connectors,
  getDefaultLabels,
  getMinRequiredPlanLabel,
} from "@/widgets/connectors";
import { Portal, Transition } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/16/solid";

import {
  RecommendedConnectionsContext,
  useRecommendedConnectionsContext,
} from "./RecommendedConnectionsContext";

export function RecommendedConnections(props: {
  abilityFilter?: Ability;
  onSelectIntegration: (option: ConnectorOption) => void;
  children: (props: {
    options: ConnectorOption[];
    onSelectIntegration: (option: ConnectorOption) => void;
    onRemoveRecommendation: (option: ConnectorOption) => Promise<void>;
  }) => React.ReactNode;
}) {
  const { data } = usePendingOnboardingConnectionsQuery({
    fetchPolicy: "cache-and-network",
  });

  const dwh = useDataWarehouseContext();
  const options = useRecommendedConnectorOptions({
    dwIntegration: dwh.integration,
    integrationIds:
      data?.getPendingOnboardingConnections.map((x) => x.integrationId) ?? [],
    abilityFilter: props.abilityFilter,
  });

  const [deleteOnboardingConnectionPreference] =
    useDeleteOnboardingConnectionPreferenceMutation({
      refetchQueries: [PendingOnboardingConnectionsDocument],
    });

  const onRemoveRecommendation = useCallback(
    async (option: ConnectorOption) => {
      await deleteOnboardingConnectionPreference({
        variables: {
          integrationId: option.value,
        },
      });
    },
    [deleteOnboardingConnectionPreference],
  );

  return (
    <Connectors
      options={options}
      abilityFilter={props.abilityFilter}
      onSelectIntegration={props.onSelectIntegration}
    >
      {({ options, onSelectIntegration }) => {
        const ctx = {
          options,
          onSelectIntegration,
          onRemoveRecommendation,
        };
        return (
          <RecommendedConnectionsContext.Provider value={ctx}>
            {props.children(ctx)}
          </RecommendedConnectionsContext.Provider>
        );
      }}
    </Connectors>
  );
}

export function RecommendedConnectorOption(props: {
  option: ConnectorOption;
  showAbilityLabels?: boolean;
  showPlanRequirement?: boolean;
}) {
  const { onRemoveRecommendation, onSelectIntegration } =
    useRecommendedConnectionsContext();

  const popperElRef = React.useRef(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: "top-end",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [0, 2] } },
    ],
  });

  const { option } = props;

  const labels = getDefaultLabels(option, props.showAbilityLabels);
  const minRequiredPlan = getMinRequiredPlanLabel(
    props.option.metadata,
    props.showPlanRequirement,
  );

  return (
    <Popover trigger="hover" hoverDelay={400} key={option.value}>
      <Popover.Button as="div">
        <ConnectorCard
          icon={<IntegrationLogoBox id={option.value} />}
          name={option.label}
          onClick={() => onSelectIntegration(option)}
          className="group"
          ref={setReferenceElement}
        >
          <ConnectorCardContent>
            <ConnectorCardTitle>{option.label}</ConnectorCardTitle>
            <ConnectorCardLabels>
              {labels.map((label) => (
                <Badge key={label} variant="outline">
                  {label}
                </Badge>
              ))}
              {minRequiredPlan && (
                <Badge className="bg-black text-white shadow-md dark:bg-yellow-600/40">
                  <SparklesIcon className="mr-1 h-3.5 w-3.5" />{" "}
                  {formatPlanName(minRequiredPlan)}
                </Badge>
              )}
            </ConnectorCardLabels>
          </ConnectorCardContent>
        </ConnectorCard>
      </Popover.Button>

      <Portal>
        <div
          ref={popperElRef}
          style={styles.popper}
          {...attributes.popper}
          className="absolute z-50"
        >
          <Transition
            as="div"
            enter="transition duration-150 ease-out"
            enterFrom="scale-90 translate-y-2 opacity-0"
            enterTo="scale-100 opacity-100 translate-y-0 "
            leave="transition duration-100 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-75 opacity-0 translate-y-2"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => setPopperElement(null)}
          >
            <Popover.Panel className="rounded-md border bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-gray-900 dark:bg-gray-700">
              <div className="flex p-1">
                <Button
                  variant="ghost"
                  size="xs"
                  onClick={() => {
                    onRemoveRecommendation(option);
                  }}
                  className="text-gray-100 outline-none ring-0 hover:bg-white/30 focus:outline-none focus:ring-0 focus:ring-offset-0"
                  icon={<CloseButtonIcon />}
                >
                  Remove
                </Button>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      </Portal>
    </Popover>
  );
}
