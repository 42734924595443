const PREFIX = "weld";
const SEPARATOR = ":";

export function createKeyCreator(namespace?: string) {
  return (...tokens: string[]) => {
    return [PREFIX, namespace, ...tokens].filter(Boolean).join(SEPARATOR);
  };
}

export const createStorageKey = createKeyCreator();

export function removeStorageItems(keys: string[]) {
  keys.forEach((key) => {
    try {
      window.localStorage.removeItem(key);
    } catch {}
  });
}
