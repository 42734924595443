import { useMemo, useRef, useState } from "react";

import { ConnectorOption, useFilterConnectors } from "@/features/connectors";
import { useConnectionsByIntegrationId } from "@/features/connectors/lib/useConnectionsByIntegrationId";
import { useControllableState } from "@/hooks/useControllableState";
import useUpdateEffect from "@/hooks/useUpdateEffect";
import { Ability, isCustomConnector } from "@/integrations";

export type UseConnectorsProps = {
  options: ConnectorOption[];
  abilityFilter?: Ability;
  onAbilityFilterChange?: (ability: Ability | null) => void;
  onOptionsFiltered?: (
    options: ConnectorOption[],
    filters: {
      abilityFilter: Ability | null;
      searchText: string;
    },
  ) => void;
};

export function useConnectors(props: UseConnectorsProps) {
  const [searchText, setSearchText] = useState("");

  const [abilityFilter, setAbilityFilter] = useControllableState({
    defaultProp: null,
    prop: props.abilityFilter ?? null,
    onChange: props.onAbilityFilterChange,
  });

  const existingConnectionsByIntegrationId = useConnectionsByIntegrationId();

  const filteredOptions = useFilterConnectors(
    props.options,
    abilityFilter,
    searchText,
    (_, defaultSearchFilterFn) => {
      return (option) => {
        if (isCustomConnector(option.integration.id) && !option.isCommunity) {
          return true;
        }
        return defaultSearchFilterFn(option);
      };
    },
  );

  const onIntegrationsFilteredRef = useRef(props.onOptionsFiltered);
  useUpdateEffect(() => {
    onIntegrationsFilteredRef.current?.(filteredOptions, {
      abilityFilter: abilityFilter ?? null,
      searchText,
    });
  }, [filteredOptions, searchText, abilityFilter]);

  const sortedOptions = useMemo(() => {
    const { existingOptions, otherOptions } = filteredOptions.reduce<{
      existingOptions: typeof filteredOptions;
      otherOptions: typeof filteredOptions;
    }>(
      (acc, option) => {
        if (existingConnectionsByIntegrationId.has(option.value)) {
          acc.existingOptions.push(option);
        } else {
          acc.otherOptions.push(option);
        }
        return acc;
      },
      {
        existingOptions: [],
        otherOptions: [],
      },
    );

    const sortFn = (
      a: (typeof filteredOptions)[0],
      b: (typeof filteredOptions)[0],
    ) => {
      return a.label.localeCompare(b.label);
    };

    return [...existingOptions.sort(sortFn), ...otherOptions.sort(sortFn)];
  }, [filteredOptions, existingConnectionsByIntegrationId]);

  return {
    options: sortedOptions,
    searchText,
    setSearchText,
    abilityFilter,
    setAbilityFilter,
    existingConnectionsByIntegrationId,
  };
}
